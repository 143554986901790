<template>
  <!-- Modal -->
  <div class="modal fade" id="transfer_me_add">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <b class="text-primary">{{ name }}</b> ҳисобига пул қабул қилиш
          </h5>
          <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close"
            id="transfer_add"></button>
        </div>
        <form @submit.prevent="$emit('transfer_me', data)">
          <div class="modal-body">
            <div class="row gap-2">
              <div class="col-10 mx-auto">
                <div class="dropdown">
                  <button class="btn btn-light btn-sm dropdown-toggle" type="button" id="select_bank_building"
                    data-mdb-toggle="dropdown" data-bs-auto-close="outside" aria-expanded="false"
                    @click="buildings_get(page_building, limit_building, search_building)">
                    {{ text_building }}
                  </button>
                  <ul class="dropdown-menu border-top-0" aria-labelledby="bank_building">
                    <li>
                      <input type="search" class="form-control form-control-sm" placeholder="қидириш ..."
                        v-model="search_building" />
                    </li>
                    <li>
                      <ul class="dropdown-items">
                        <li @click="select(0, 'барчаси')">
                          <a class="dropdown-item">барчаси</a>
                        </li>
                        <li v-for="item in buildings" :key="item"
                          @click="select(item.Buildings.id, item.Buildings.name)">
                          <a class="dropdown-item">{{ item.Buildings.name }}</a>
                        </li>
                      </ul>
                    </li>
                    <li v-if="buildings != ''">
                      <hr class="dropdown-divider m-0" />
                    </li>
                    <li>
                      <nav aria-label="Page navigation example" class="mt-2" v-if="buildings != ''">
                        <ul class="pagination pagination-sm justify-content-end">
                          <li class="page-item">
                            <button type="button" class="page-link" @click="
                              buildings_get(
                                page_building,
                                limit_building,
                                search_building
                              )
                              ">
                              <i class="fa-solid fa-rotate"></i>
                            </button>
                          </li>
                          <li class="page-item">
                            <button type="button" class="page-link" @click="page_building--"
                              :disabled="page_building == 0">
                              <i class="fa-solid fa-angle-left text-dark"></i>
                            </button>
                          </li>
                          <li class="page-item">
                            <button type="button" class="page-link text-dark" disabled>
                              {{ page_building + 1 }}
                            </button>
                          </li>
                          <li class="page-item">
                            <button type="button" class="page-link" @click="page_building++"
                              :disabled="page_building == pages_building - 1">
                              <i class="fa-solid fa-angle-right text-dark"></i>
                            </button>
                          </li>
                        </ul>
                      </nav>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-10 mx-auto">
                <label for="balance1">
                  Сумма:
                  <span class="text-primary">{{ new Intl.NumberFormat().format(data.money) }} сўм</span></label>
                <div class="input-group input-group-sm">
                  <input id="balance1" type="number" step="any" class="form-control" v-model="data.money" min="0"
                    required />
                  <span class="input-group-text bg-white">сўм</span>
                </div>
              </div>
              <div class="col-10 mx-auto">
                <label for="comment1"> Изох: </label>
                <textarea id="comment1" class="form-control" v-model="data.comment" required></textarea>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn btn-outline-primary btn-sm" id="transfer_disabled">
              <i class="fa-solid fa-circle-check"></i>
            </button>
            <button type="button" class="btn btn-outline-danger btn-sm" data-mdb-dismiss="modal">
              <i class="fa-solid fa-circle-xmark"></i>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

  <div class="modal fade" id="transfer_to_add">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">
            <b class="text-primary">{{ name }}</b> ҳисобидан пул ўтказиш
          </h5>
          <button type="button" class="btn-close" data-mdb-dismiss="modal" aria-label="Close" id="transfer_to"></button>
        </div>
        <ul class="nav nav-tabs nav-justified" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <a class="nav-link active" id="pills-home-tab" data-mdb-toggle="pill" data-mdb-target="#pills-home"
              type="button" role="tab" aria-controls="pills-home" aria-selected="true">
              ҳисоб рақамига пул ўтказиш
            </a>
          </li>
          <li class="nav-item" role="presentation">
            <a class="nav-link" id="pills-profile-tab" data-mdb-toggle="pill" data-mdb-target="#pills-profile"
              type="button" role="tab" aria-controls="pills-profile" aria-selected="false">
              кассирга пул ўтказиш
            </a>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
            <Transfer_bank_account_body @transfer_to="transfer_to" :id="id" />
          </div>
          <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
            <Transfer_cashier_body @transfer_to_cashier="transfer_to_cashier" :id="id" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { catchError } from "@/utils/request/handle_api_err";
import Transfer_bank_account_body from "../transfer/transfer_bank_account_body.vue";
import Transfer_cashier_body from "../transfer/transfer_cashier_body.vue";
import { building_get } from "@/utils/request/request";
export default {
  name: "TransferItem",
  props: ["id", "name"],
  emits: ["transfer_to", "transfer_me", "transfer_to_cashier"],
  data() {
    return {
      data: {
        money: null,
        from_: 0,
        to_: null,
        comment: "",
        building_id:''
      },
      page_building: 0,
      pages_building: null,
      limit_building: 25,
      search_building: "",
      buildings: [],
      text_building: "объектлар",
    };
  },
  watch: {
    id(e) {
      this.data.to_ = e;
      this.data.money = null;
      this.data.comment = "";
      this.data.building_id = ''
    },
    limit_building(e) {
      this.page_building = 0;
      this.buildings_get(this.page_building, e, this.search_building);
    },
    page_building(e) {
      this.buildings_get(e, this.limit_building, this.search_building);
    },
    search_building(e) {
      this.page_building = 0;
      if (e.length > 0) {
        this.buildings_get(
          this.page_building,
          this.limit_building,
          this.search_building
        );
      } else if (e.length == 0) {
        this.buildings_get(
          this.page_building,
          this.limit_building,
          this.search_building
        );
        this.page_building = 0;
      }
    },
  },
  methods: {
    select(id, name) {
      this.data.building_id = id;
      this.text_building = name;
      document.getElementById("select_bank_building").click();
    },
    transfer_to(e) {
      this.$emit("transfer_to", e);
    },
    transfer_to_cashier(e) {
      this.$emit("transfer_to_cashier", e);
    },
    buildings_get(page_building, limit_building, search_building) {
      this.$store.dispatch("setLoading", { isLoading: true });

      building_get(page_building, limit_building, search_building)
        .then((res) => {
          this.buildings = res.data.data;
          this.pages_building = res.data.pages;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
  },
  components: { Transfer_bank_account_body, Transfer_cashier_body },
};
</script>

<!-- <style lang="scss" scoped>
</style> -->
