<template>
  <div class="row row_building" v-if="role == 'admin'">
    <div class="col-md-4 col-sm-6 col-lg-4 col-xxl-4 my-1" v-for="(item, inc) in data" :key="item">
      <div class="card h-100" :class="item.smeta_work_end_date != null
          ? date < item.smeta_work_end_date &&
            date_sort(item.smeta_work_end_date) > 5
            ? 'success'
            : date < item.smeta_work_end_date &&
              date_sort(item.smeta_work_end_date) <= 5 &&
              date_sort(item.smeta_work_end_date) >= 3
              ? 'warning'
              : 'danger'
          : 'dark'
        ">
        <div class="card-body text-center">
          <span class="float-start badge bg-primary">{{ inc + 1 }}</span>
          <h5 class="card-title text-center mb-0">
            <span class="descinline">
              {{ shorttext(item.Buildings.name) }}
            </span>
            <span class="descempty"> {{ item.Buildings.name }} </span>
            <span class="text-primary" style="cursor: pointer" @click="toggler($event)"
              v-if="item.Buildings.name.length > 15">
              яна</span>
          </h5>
          <hr class="mt-2 mb-2" :class="item.smeta_work_end_date != null
              ? date < item.smeta_work_end_date &&
                date_sort(item.smeta_work_end_date) > 5
                ? 'success'
                : date < item.smeta_work_end_date &&
                  date_sort(item.smeta_work_end_date) <= 5 &&
                  date_sort(item.smeta_work_end_date) >= 3
                  ? 'warning'
                  : 'danger'
              : 'dark'
            " />
          <h6><b>Манзил: </b> {{ item.Buildings.address }}</h6>
          <h6>
            <b>Бюджет: </b>
            {{
              Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                maximumSignificantDigits: item.Buildings.budget.length,
                minimumSignificantDigits: 1,
              }).format(item.Buildings.budget)
            }}
            сўм
          </h6>
          <h6 data-mdb-toggle="modal" data-mdb-target="#spented_money" class="cursor-pointer"
            @click="building_id = item.Buildings.id">
            <b>Сарфланган сумма: </b>{{ set_spented_money(item.Buildings.id) }} сўм
          </h6>
          <h6>
            <b>Бошлаш санаси: </b>
            {{
              new Date(item.Buildings.start_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </h6>
          <h6>
            <b>Тугаш санаси: </b>
            {{
              new Date(item.Buildings.end_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </h6>
          <span class="badge rounded-pill bg-warning" v-if="item.Buildings.status == 'wait'">кутилмоқда</span>
          <span class="badge rounded-pill bg-primary" v-else-if="item.Buildings.status == 'start'">қурилмоқда</span>
          <span class="badge rounded-pill bg-success" v-else-if="item.Buildings.status == 'end'">якунланди</span>
          <hr class="mt-2 mb-2" :class="item.smeta_work_end_date != null
              ? date < item.smeta_work_end_date &&
                date_sort(item.smeta_work_end_date) > 5
                ? 'success'
                : date < item.smeta_work_end_date &&
                  date_sort(item.smeta_work_end_date) <= 5 &&
                  date_sort(item.smeta_work_end_date) >= 3
                  ? 'warning'
                  : 'danger'
              : 'dark'
            " />
          <div class="d-flex justify-content-center zoom">
            <div class="coll mx-1">
              <button class="btn btn-sm btn-primary" data-mdb-toggle="modal" data-mdb-target="#foremans_warehousemans"
                @click="select_id(item.Buildings.id, item.Buildings.name, 1)">
                <i class="fa-solid fa-users"></i>
              </button>
            </div>
            <div class="coll mx-1">
              <Route_map :lat="item.Buildings.map_lat" :long="item.Buildings.map_long" />
            </div>
            <div class="coll mx-1">
              <router-link :to="{
                name: 'building',
                query: { id: item.Buildings.id, name: item.Buildings.name },
              }" class="btn btn-sm btn-primary">
                <i class="fa-solid fa-right-long"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <router-link :to="{
                name: 'warehouse_product',
                query: { id: item.Buildings.id, name: item.Buildings.name },
              }" class="btn btn-sm btn-primary">
                <i class="fa-solid fa-box"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <router-link :to="{
                name: 'building_history',
                query: { id: item.Buildings.id },
              }" class="btn btn-primary btn-sm">
                <i class="fa-solid fa-clock-rotate-left"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <router-link :to="{
                name: 'building_works',
                query: { id: item.Buildings.id, name: item.Buildings.name },
              }" class="btn btn-sm btn-primary">
                <i class="fa-solid fa-clipboard-list"></i>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row row_building" v-else-if="role == 'pto'">
    <div class="col-md-4 col-sm-6 col-lg-4 col-xxl-4 my-1" v-for="(item, inc) in data" :key="item">
      <div class="card h-100" :class="item.smeta_work_end_date != null
          ? date < item.smeta_work_end_date &&
            date_sort(item.smeta_work_end_date) > 5
            ? 'success'
            : date < item.smeta_work_end_date &&
              date_sort(item.smeta_work_end_date) <= 5 &&
              date_sort(item.smeta_work_end_date) >= 3
              ? 'warning'
              : 'danger'
          : 'dark'
        ">
        <div class="card-body text-center">
          <span class="float-start badge bg-primary">{{ inc + 1 }}</span>
          <h5 class="card-title text-center mb-0">
            <span class="descinline">
              {{ shorttext(item.Buildings.name) }}
            </span>
            <span class="descempty"> {{ item.Buildings.name }} </span>
            <span class="text-primary" style="cursor: pointer" @click="toggler($event)"
              v-if="item.Buildings.name.length > 15">
              яна</span>
          </h5>
          <hr class="mt-2 mb-2" :class="item.smeta_work_end_date != null
              ? date < item.smeta_work_end_date &&
                date_sort(item.smeta_work_end_date) > 5
                ? 'success'
                : date < item.smeta_work_end_date &&
                  date_sort(item.smeta_work_end_date) <= 5 &&
                  date_sort(item.smeta_work_end_date) >= 3
                  ? 'warning'
                  : 'danger'
              : 'dark'
            " />
          <h6><b>Манзил: </b> {{ item.Buildings.address }}</h6>
          <h6>
            <b>Бюджет: </b>
            {{
              Intl.NumberFormat("en-US", {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
                maximumSignificantDigits: item.Buildings.budget.length,
                minimumSignificantDigits: 1,
              }).format(item.Buildings.budget)
            }}
            сўм
          </h6>
          <h6>
            <b>Бошлаш санаси: </b>
            {{
              new Date(item.Buildings.start_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </h6>
          <h6>
            <b>Тугаш санаси: </b>
            {{
              new Date(item.Buildings.end_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </h6>
          <span class="badge rounded-pill bg-warning" v-if="item.Buildings.status == 'wait'">кутилмоқда</span>
          <span class="badge rounded-pill bg-primary" v-else-if="item.Buildings.status == 'start'">қурилмоқда</span>
          <span class="badge rounded-pill bg-success" v-else-if="item.Buildings.status == 'end'">якунланди</span>
          <hr class="mt-2 mb-2" :class="item.smeta_work_end_date != null
              ? date < item.smeta_work_end_date &&
                date_sort(item.smeta_work_end_date) > 5
                ? 'success'
                : date < item.smeta_work_end_date &&
                  date_sort(item.smeta_work_end_date) <= 5 &&
                  date_sort(item.smeta_work_end_date) >= 3
                  ? 'warning'
                  : 'danger'
              : 'dark'
            " />
          <div class="d-flex justify-content-center zoom">
            <div class="coll mx-1">
              <router-link :to="{
                name: 'smeta',
                query: { id: item.Buildings.id, name: item.Buildings.name },
              }" class="btn btn-sm btn-primary">
                <i class="fa-solid fa-right-long"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <router-link :to="{
                name: 'building_works',
                query: { id: item.Buildings.id, name: item.Buildings.name },
              }" class="btn btn-sm btn-primary">
                <i class="fa-solid fa-clipboard-list"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <button class="btn btn-sm btn-primary" data-mdb-toggle="modal" data-mdb-target="#foremans_warehousemans"
                @click="select_id(item.Buildings.id, item.Buildings.name, 1)">
                <i class="fa-solid fa-users"></i>
              </button>
            </div>
            <div class="coll mx-1">
              <router-link :to="{
                name: 'building_products',
                query: { id: item.Buildings.id, name: item.Buildings.name },
              }" class="btn btn-sm btn-primary">
                <i class="fa-solid fa-box"></i>
              </router-link>
            </div>
            <div class="coll mx-1">
              <button class="btn btn-sm btn-primary" data-mdb-toggle="modal" data-mdb-target="#building_document"
                @click="select_id(item.Buildings.id, item.Buildings.name, 2)">
                <i class="fa-solid fa-file-arrow-up"></i>
              </button>
            </div>
            <div class="coll mx-1">
              <Route_map :lat="item.Buildings.map_lat" :long="item.Buildings.map_long" />
            </div>

            <div class="coll mx-1" v-if="item.Buildings.status == 'start'">
              <button class="btn btn-sm btn-success" @click="finish(item.Buildings)">
                <i class="fa-solid fa-flag-checkered"></i>
              </button>
            </div>
            <div class="coll mx-1" v-else-if="item.Buildings.status == 'end'">
              <button class="btn btn-sm btn-primary" @click="start(item.Buildings)">
                <i class="fa-solid fa-play"></i>
              </button>
            </div>

            <div class="coll mx-1" v-if="item.Buildings.status == 'wait'">
              <button class="btn btn-sm btn-primary" @click="start(item.Buildings)">
                <i class="fa-solid fa-play"></i>
              </button>
            </div>
            <div class="coll mx-1" v-if="item.Buildings.status == 'wait'">
              <button class="btn btn-sm btn-warning" data-mdb-toggle="modal" data-mdb-target="#building_put"
                @click="items(item.Buildings)">
                <i class="fa fa-edit text-white"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="table-responsive mt-3" v-else>
    <table class="table table-sm table-hover building">
      <thead class="">
        <tr>
          <th>№</th>
          <th>Номи</th>
          <th>Манзил</th>
          <th>Бошлаш санаси</th>
          <th>Тугаш санаси</th>
          <th>Статус</th>
          <th></th>
        </tr>
      </thead>
      <tbody v-if="role == 'prorap' || role == 'omborchi'">
        <tr v-for="(item, inc) in data" :key="item">
          <td>{{ inc + 1 }}</td>

          <td>
            <p style="min-width: 250px; width: 250px">
              {{ item.Buildings.name }}
            </p>
          </td>
          <td>{{ item.Buildings.address }}</td>
          <td>
            {{
              new Date(item.Buildings.start_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            {{
              new Date(item.Buildings.end_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            <span class="badge rounded-pill bg-warning" v-if="item.Buildings.status == 'wait'">кутилмоқда</span>
            <span class="badge rounded-pill bg-primary" v-else-if="item.Buildings.status == 'start'">қурилмоқда</span>
            <span class="badge rounded-pill bg-success" v-else-if="item.Buildings.status == 'end'">якунланди</span>
          </td>
          <td>
            <button class="btn btn-sm btn-warning mx-1" data-mdb-toggle="modal" data-mdb-target="#building_put"
              @click="items(item.Buildings.building)" v-if="item.Buildings.status == 'wait' && role == 'pto'">
              <i class="fa fa-edit text-white"></i>
            </button>

            <button class="btn btn-sm btn-success mx-1" v-else-if="item.Buildings.status == 'start' && role == 'pto'"
              @click="finish(item.Buildings.building)">
              <i class="fa-solid fa-flag-checkered"></i>
            </button>
            <button class="btn btn-sm btn-primary mx-1" data-mdb-toggle="modal"
              data-mdb-target="#foremans_warehousemans" @click="select_id(item.Buildings.id)"
              v-if="role == 'admin' || role == 'pto'">
              <i class="fa-solid fa-users"></i>
            </button>
            <Route_map :lat="item.Buildings.map_lat" :long="item.Buildings.map_long" />
            <router-link :to="{
              name: 'smeta',
              query: { id: item.Buildings.id, name: item.Buildings.id },
            }" class="btn btn-sm btn-primary mx-1">
              <i class="fa-solid fa-right-long"></i>
            </router-link>
            <!-- <router-link
              v-if="item.Buildings.status == 'start'"
              :to="{
                name: 'proche_sorovlar',
                query: { id: item.Buildings.id },
              }"
              class="btn btn-sm btn-primary mx-1"
            >
              <i class="fa-solid fa-rectangle-list"></i>
            </router-link> -->
            <button class="btn btn-sm btn-primary mx-1 d-block mt-1"
              v-if="item.Buildings.status == 'wait' && role == 'pto'" @click="start(item.Buildings.building)">
              бошлаш
            </button>
            <router-link :to="{ name: 'workers', query: { id: item.Buildings.id } }" class="btn btn-sm btn-primary mx-1"
              v-if="role == 'prorap'">
              <i class="fa-solid fa-users"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
      <tbody v-else-if="role == 'operator'">
        <tr v-for="(item, inc) in data" :key="item">
          <td>{{ inc + 1 }}</td>
          <td>
            <p style="min-width: 250px; width: 250px">
              {{ item.Buildings.name }}
            </p>
          </td>
          <td>{{ item.Buildings.address }}</td>
          <td>
            {{
              new Date(item.Buildings.start_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            {{
              new Date(item.Buildings.end_date)
                .toLocaleDateString({
                  year: "numeric",
                  month: "numeric",
                  day: "numeric",
                })
                .slice(0, 10)
            }}
          </td>
          <td>
            <span class="badge rounded-pill bg-warning" v-if="item.Buildings.status == 'wait'">кутилмоқда</span>
            <span class="badge rounded-pill bg-primary" v-else-if="item.Buildings.status == 'start'">қурилмоқда</span>
            <span class="badge rounded-pill bg-success" v-else-if="item.Buildings.status == 'end'">якунланди</span>
          </td>
          <td>
            <Route_map :lat="item.Buildings.map_lat" :long="item.Buildings.map_long" />

            <router-link v-if="item.Buildings.status == 'start'" :to="{
              path: '/buildings/product_request',
              query: { id: item.Buildings.id },
            }" class="btn btn-sm btn-primary mx-1">
              <i class="fa-solid fa-rectangle-list"></i>
            </router-link>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <Building_put :object="item" @put="put" />

  <Building_documents v-if="role == 'pto'" :id="building_id" :name="building_name" @document_add="document_add"
    :data="documents" @remove="remove" />

  <!-- Modal -->

  <div class="modal fade" id="foremans_warehousemans" tabindex="-1" aria-labelledby="foremans_warehousemans"
    aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <button type="button" class="btn-close float-end" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col">
              <Foreman :building_id="building_id" :data="foremans" @get="select_id" />
            </div>
            <div class="col">
              <Warehouseman :building_id="building_id" :data="warehousemans" @get="get_warehousemans" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- SPENTED MONEY -->
  <div class="modal fade" id="spented_money" tabindex="-1" aria-labelledby="spented_money" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header border-bottom-0">
          <h5 class="modal-title">Сарфланган сумма</h5>
          <button type="button" class="btn-close float-end" data-mdb-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body h-auto">
          <h6>
            Jami chiqimlar:
            <b>
              {{
                Intl.NumberFormat().format(
                  find_spented_money(building_id)?.sum_variable_expense_price +
                  find_spented_money(building_id)?.sum_fixed_expense_price
                )
              }}</b>
            сўм
          </h6>
          <h6>
            Taminotchi korxonaga naxt raqamdan o'tkazilgan pullar:
            <b>
              {{
                Intl.NumberFormat().format(
                  find_spented_money(building_id)
                    ?.sum_market_expense_naxt_supply
                )
              }}</b>
            сўм
          </h6>
          <h6>
            Taminotchi korxonaga hisob raqamdan o'tkazilgan pullar:
            <b>
              {{
                Intl.NumberFormat().format(
                  find_spented_money(building_id)
                    ?.sum_market_expense_shot_supply +
                  find_spented_money(building_id)
                    ?.sum_market_expense_supply_by_b_a
                )
              }}</b>
            сўм
          </h6>
          <h6>
            Smetadagi ishlar uchun to'langan pullar:
            <b>{{
              Intl.NumberFormat().format(
                find_spented_money(building_id)?.sum_smeta_work_expense
              )
            }}</b>
            сўм
          </h6>
          <h6>
            Smetadan tashqari ishlar uchun to'langan pullar:
            <b>
              {{
                Intl.NumberFormat().format(
                  find_spented_money(building_id)?.sum_other_work_expense
                )
              }}</b>
            сўм
          </h6>
          <h6>
            Bugalter qilgan chiqimlar ro'yhati:
            <b>
              {{
                Intl.NumberFormat().format(
                  find_spented_money(building_id)?.sum_fixed_expense_price_from_bugalter
                )
              }}</b>
            сўм
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  building_d_add,
  building_d_get,
  building_d_remove,
  building_put,
  foreman_get,
  warehouseman_get,
} from "@/utils/request/request";
import Building_put from "./building_put.vue";
import { catchError, success } from "@/utils/request/handle_api_err";
import Swal from "sweetalert2";
import Foreman from "../foremans_warehousemans/foreman.vue";
import Warehouseman from "../foremans_warehousemans/warehouseman.vue";
import Building_documents from "./building_documents.vue";
import Route_map from "@/utils/map/route_map.vue";
export default {
  name: "BuildingItem",
  props: { data: Array, spented_money: Array },
  emits: ["get_buildings"],
  data() {
    return {
      item: {},
      foremans: [],
      warehousemans: [],
      building_id: null,
      building_name: "",
      role: localStorage.getItem("role"),
      documents: [],
      date: new Date().toISOString().slice(0, 10),
    };
  },
  methods: {
    items(e) {
      this.item = {
        id: e.id,
        name: e.name,
        address: e.address,
        budget: e.budget,
        start_date: e.start_date,
        end_date: e.end_date,
        map_long: e.map_long,
        map_lat: e.map_lat,
        status: e.status,
      };
    },
    put(e) {
      this.$store.dispatch("setLoading", { isLoading: true });
      document.getElementById("building_disabled_put").disabled = true;
      building_put(e)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("buildings_put").click();
          document.getElementById("building_disabled_put").disabled = false;
          success(res);
          this.$emit("get_buildings");
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("building_disabled_put").disabled = false;
        });
    },
    start(e) {
      if (e.status == "end") {
        this.$store.dispatch("setLoading", { isLoading: true });
        e.status = "start";
        building_put(e)
          .then((res) => {
            this.$store.dispatch("setLoading", { isLoading: false });
            success(res);
            this.$emit("get_buildings");
          })
          .catch((err) => {
            catchError(err);
            this.$store.dispatch("setLoading", { isLoading: false });
          });
      } else {
        const Toast = Swal.mixin({
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 10000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });

        Toast.fire({
          icon: "warning",
          width: 600,
          title:
            "Обект қурилиши бошланганидан сўнг унинг маълумотларини ўзгартириб бўлмайди",
          showCancelButton: true,
          showConfirmButton: true,
          confirmButtonText: "<i class='fa-solid fa-circle-check'></i>",
          cancelButtonText: "<i class='fa-solid fa-circle-xmark'></i>",
          confirmButtonColor: "#0D6EFD",
          cancelButtonColor: "#DC3545",
        }).then((result) => {
          if (result.isConfirmed) {
            this.$store.dispatch("setLoading", { isLoading: true });
            e.status = "start";
            building_put(e)
              .then((res) => {
                this.$store.dispatch("setLoading", { isLoading: false });
                success(res);
                this.$emit("get_buildings");
              })
              .catch((err) => {
                catchError(err);
                this.$store.dispatch("setLoading", { isLoading: false });
              });
          } else {
            result;
          }
        });
      }
    },
    finish(e) {
      const Toast = Swal.mixin({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 10000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      });

      Toast.fire({
        icon: "warning",
        width: 600,
        title: "Объектни ростанҳам якунламоқчимисиз",
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: "<i class='fa-solid fa-circle-check'></i>",
        cancelButtonText: "<i class='fa-solid fa-circle-xmark'></i>",
        confirmButtonColor: "#0D6EFD",
        cancelButtonColor: "#DC3545",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("setLoading", { isLoading: true });
          e.status = "end";
          building_put(e)
            .then((res) => {
              this.$store.dispatch("setLoading", { isLoading: false });
              success(res);
              this.$emit("get_buildings");
            })
            .catch((err) => {
              catchError(err);
              this.$store.dispatch("setLoading", { isLoading: false });
            });
        } else {
          result;
        }
      });
    },
    select_id(id, name, nm) {
      this.building_id = id;
      this.building_name = name;
      if (nm == 1) {
        this.$store.dispatch("setLoading", { isLoading: true });
        foreman_get(id, 0)
          .then((res) => {
            this.foremans = res.data;
            this.$store.dispatch("setLoading", { isLoading: false });
            this.get_warehousemans(id);
          })
          .catch((err) => {
            catchError(err);
            this.$store.dispatch("setLoading", { isLoading: false });
          });
      } else {
        this.$store.dispatch("setLoading", { isLoading: true });
        building_d_get(id)
          .then((res) => {
            this.documents = res.data;
            this.$store.dispatch("setLoading", { isLoading: false });
          })
          .catch((err) => {
            catchError(err);
            this.$store.dispatch("setLoading", { isLoading: false });
          });
      }
    },
    get_warehousemans(e) {
      this.$store.dispatch("setLoading", { isLoading: true });
      warehouseman_get(e)
        .then((res) => {
          this.warehousemans = res.data;
          this.$store.dispatch("setLoading", { isLoading: false });
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
        });
    },
    document_add(e) {
      document.getElementById("document_button").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      building_d_add(e, this.building_id)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("document_button").disabled = false;
          success(res);
          this.select_id(this.building_id, this.building_name, 2);
          e.file = "";
          e.comment = "";
          document.getElementById("txt").innerHTML = "файл юклаш";
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("txt").innerHTML = "файл юклаш";
          e.file = "";
          e.comment = "";
        });
    },
    remove(e) {
      document.getElementById("remove_document").disabled = true;
      this.$store.dispatch("setLoading", { isLoading: true });
      building_d_remove(e)
        .then((res) => {
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("remove_document").disabled = false;
          success(res);
          this.select_id(this.building_id, this.building_name, 2);
        })
        .catch((err) => {
          catchError(err);
          this.$store.dispatch("setLoading", { isLoading: false });
          document.getElementById("remove_document").disabled = false;
        });
    },
    toggler(e) {
      if (e.target.innerText == " яна") {
        e.target.innerText = "ёпиш";
        e.target.parentElement.children[0].className = "";
        e.target.parentElement.children[0].className = "descempty";
        e.target.parentElement.children[1].className = "";
        e.target.parentElement.children[1].className = "descinline";
      } else {
        e.target.innerText = " яна";
        e.target.parentElement.children[0].className = "descinline";
        e.target.parentElement.children[1].className = "descempty";
      }
    },
    date_sort(e) {
      let date1 = new Date(e);
      let date2 = new Date(this.date);

      const diffTime = Math.abs(date1 - date2);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      return Number(diffDays);
    },
    find_spented_money(e) {
      return this.spented_money.find((item) => item.building_id == e);
    },
    set_spented_money(e) {
      let building = this.find_spented_money(e);
      let summa =
        building.sum_variable_expense_price + // Jami chiqimlar
        building.sum_fixed_expense_price + // Jami chiqimlar
        building.sum_market_expense_naxt_supply + // Taminotchi korxonaga naxt raqamdan o'tkazilgan pullar
        building.sum_market_expense_shot_supply + // Taminotchi korxonaga hisob raqamdan o'tkazilgan pullar
        building.sum_market_expense_supply_by_b_a + // Taminotchi korxonaga hisob raqamdan o'tkazilgan pullar
        building.sum_smeta_work_expense + // Smetadagi ishlar uchun to'langan pullar
        building.sum_other_work_expense + // Smetadan tashqari ishlar uchun to'langan pullar
        building.sum_fixed_expense_price_from_bugalter;
      return Intl.NumberFormat("en-US", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
        maximumSignificantDigits: summa.length,
        minimumSignificantDigits: 1,
      }).format(summa);
    },
  },
  computed: {
    shorttext() {
      return (v) => {
        return v.substring(0, 15);
      };
    },
  },
  components: {
    Building_put,
    Foreman,
    Warehouseman,
    Building_documents,
    Route_map,
  },
};
</script>

<style lang="scss" scoped>
table {
  min-width: 1000px;
  width: 100%;
}

.table-responsive {
  max-height: 66vh;
}

div.row_building {
  max-height: 68vh;
  overflow: auto;
}

@media (max-width: 500px) {
  .table-responsive {
    max-height: 85vh;
  }
}

.modal-content {
  border: none;
}

.modal-body {
  height: 85vh;
}

.descinline {
  display: inline;
}

.descempty {
  display: none;
}

hr {
  border: none;
  opacity: 1;
  border-radius: 50px;
}

hr.success {
  background: green;
  animation: success 0.8s infinite alternate linear;
}

hr.dark {
  background: black;
  animation: darker 0.8s infinite alternate linear;
}

hr.danger {
  background: red;
  animation: danger 0.8s infinite alternate linear;
}

hr.warning {
  background: #ffc107;
  animation: warning 0.8s infinite alternate linear;
}

.card.success {
  border: 1px solid green;
  animation: success 0.8s infinite alternate linear;
}

.card.dark {
  border: 1px solid black;
  animation: darker 0.8s infinite alternate linear;
}

.card.danger {
  border: 1px solid red;
  animation: danger 0.8s infinite alternate linear;
}

.card.warning {
  border: 1px solid #ffc107;
  animation: warning 0.8s infinite alternate linear;
}

@keyframes success {
  0% {
    box-shadow: 0 0 0 1.8px rgb(0, 128, 0, 0.4);
  }
}

@keyframes danger {
  0% {
    box-shadow: 0 0 0 1.8px rgb(255, 0, 0, 0.4);
  }
}

@keyframes warning {
  0% {
    box-shadow: 0 0 0 1.8px rgb(255, 193, 7, 0.4);
  }
}

@keyframes darker {
  0% {
    box-shadow: 0 0 0 1.8px rgb(0, 0, 0, 0.4);
  }
}

.zoom .coll:hover {
  transform: scale(1.1) translateY(-2px);
}

.zoom .coll {
  margin: 5px 0px;
  transition: all 0.1s linear;
}

.d-flex.justify-content-center.zoom {
  zoom: 92%;
  flex-wrap: wrap;
}
</style>
